import React from "react";
import styled from "styled-components";

import { MainTemplate } from "../templates/mainTemplate";
import { Seo } from "../components/Seo";

const NominateStyles = styled.section``;

const Nominate = () => {
  return (
    <>
      <Seo title="WDN | Nominate" />
      <MainTemplate>
        <NominateStyles>
          <h2>Nominate</h2>
        </NominateStyles>
      </MainTemplate>
    </>
  );
};

export default Nominate;
